// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import error from "./error"
import service from "./service"
import teacher from "./teacher"
import admins from "./admins"

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  error,
  service,
  teacher,
  admins
})

export default rootReducer
