import {call, put} from "redux-saga/effects"
import actions from "../../../graphql/actions/administrator"

export function *getAdministrators() {
    const admins = yield call(actions.getAdmins)
    yield put({type: 'SET_ADMINS', payload: admins})
}

export function *createAdministrator(action) {
    // const teacher = yield call(actions.createTeacher, action.payload)
}
