import { gql } from '@apollo/client'
import client from './../apollo-settings'

const actions = {
    login: async (params) => {
        try {
            const { username, password } = params
            const { data } = await client.query({
                query: gql`
                    mutation($username: String!, $password: String!) {
                        adminLogin(username: $username, password: $password) {
                            id
                            username
                            last_name
                            first_name
                            token
                        }
                    }
                `,
                variables: {
                    username,
                    password
                }
            })

           // console.log(data)
            return data ? data.adminLogin : null
        } catch (e) {
            console.log(e)
        }
    },
    getPermissions: async () => {
        try {
            const data = await client.query({
                query: gql`
                    query {
                        adminCheck{
                            id
                            permissions
                        }
                    }
                `
            })
            console.log(data)
        } catch (e) {
            console.log(e)
        }
    },
    getCheck: async () => {
        try {
            const {data} = await client.query({
                query: gql`
                    query {
                        adminCheck {
                            id
                        }
                    }
                `
            })
            console.log(data)
        } catch (e) {
            console.log(e)
        }
    },
    logOut: async () => {
        try {
            const {data} = await client.query({
                query: gql`
                    mutation {
                        adminLogOut {
                            code
                        }
                    }
                `
            })
        } catch (e) {
            console.log(e)
        }
    },
    forgotPass: async (email, token) => {
        try {
            const {data} = await client.query({
                query: gql`
                    mutation AuthResolver($data: AuthInput!) {
                        ResetPassword(data: $data) {
                            error
                            message
                        }
                    }
                `,
                variables: {
                   data: {
                       email,
                       token
                   }
                }
            })
            return data.ResetPassword
        } catch (e) {
            console.log(e)
            return null
        }
    }
}

export default actions
