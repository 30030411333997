// **  Initial State
const initialState = null

const errorReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SHOW_ERROR':
            return action.payload
        case 'HIDE_ERROR':
            return null
        default:
            return state
    }
}

export default errorReducer
