import { gql } from '@apollo/client'
import client from './../apollo-settings'

const actions = {
    getAdmins: async () => {
        try {
            const { data } = await client.query({
                query: gql`
                    query AdminDataResolver($params: ReactAdminListParams!) {
                        adminAdminList(params: $params) {
                            data {
                                id
                                username
                                last_name
                                first_name
                                email
                            }
                            total
                        }
                    }
                `,
                variables: {
                    params: {pagination: {page: 1, perPage: 99999}, sort: {field: "id", order: "ASC"}, filter: []}
                }
            })
            return data ? data.adminAdminList.data : []
        } catch (e) {
            console.log(e)
            return []
        }
    },
    getAdmin: async (id) => {
        try {
            const {data} = await client.query({
                query: gql`
                    query AdminDataResolver($id: String!) {
                        adminAdminGetOne(id: $id) {
                            id
                            username
                            last_name
                            first_name
                            email
                            token
                            permissions
                            isBlocked
                        }
                    }
                `,
                variables: {
                    id: id.toString()
                }
            })
            return data ? data.adminAdminGetOne : null
        } catch (e) {
            console.log(e)
            return null
        }
    },
    createAdmin: async (value) => {
        try {
            const {data, errors} = await client.query({
                query: gql`
                    mutation AdminDataResolver($data: AdminInput!) {
                        adminAdminCreate(data: $data) {
                            id
                            username
                            last_name
                            first_name
                            token
                            permissions
                            isBlocked
                            role
                        }
                    }
                `,
                variables: {
                    data: value
                }
            })
            // console.log(data, errors)
            return {data, error: errors ? errors[0].message : null}
        } catch (e) {
            console.log(e)
            return null
        }
    },
    updateAdmin: async (id, data) => {
        try {
            const {admin} = await client.query({
                query: gql`
                    mutation AdminDataResolver($id: Int!, $data: AdminInput!) {
                        adminAdminUpdate(id: $id, data: $data) {
                            id
                            username
                            last_name
                            first_name
                            token
                            permissions
                            isBlocked
                            role
                        }
                    }
                `,
                variables: {
                    id,
                    data
                }
            })
            return admin ? admin.adminAdminUpdate : null
        } catch (e) {
            console.log(e)
            return null
        }
    },
    deleteAdmin: async (id) => {
        try {
            const {data} = await client.query({
                query: gql`
                    mutation AdminDataResolver($id: Int!) {
                        adminAdminDelete(id: $id) {
                            id
                            username
                            last_name
                            first_name
                            token
                            permissions
                            isBlocked
                            role
                        }
                    }
                `,
                variables: {
                    id
                }
            })
            return data ? data.adminAdminDelete : null
        } catch (e) {
            console.log(e)
            return null
        }
    },
    deleteAdminMany: async (ids) => {
        try {
            const {data} = await client.query({
                query: gql`
                    mutation AdminDataResolver($ids: [Int!]!) {
                        adminAdminDeleteMany(ids: $ids) {
                            ids
                        }
                    }
                `,
                variables: {
                    ids
                }
            })
            return data ? data.adminAdminDeleteMany : null
        } catch (e) {
            console.log(e)
            return null
        }
    }
}

export default actions
