import {call, put} from "redux-saga/effects"
import actions from "../../../graphql/actions/auth"

export function *sagaLogin(signInData) {
    const login = yield call(actions.login, signInData.payload)
    if (!login) {
        yield put({type: 'SHOW_ERROR', payload: 'User is not found. Please check the entered data!'})
    } else {
        localStorage.setItem('token', login.token)
        delete login.token
        yield put({type: 'LOG_IN', payload: login})
        yield put({type: 'HIDE_ERROR', payload: null})
        signInData.payload.history.push('/admins')
    }
}

export function *sagaLogout() {
    yield call(actions.logOut)
    localStorage.removeItem("token")
    yield put({type: 'LOG_OUT'})
}


export function *forgotPassword(action) {
    yield call(actions.forgotPass, action.payload)
}
