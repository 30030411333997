import {call, put} from "redux-saga/effects"
import actions from "../../../graphql/actions/teacher"

export function *getTeacher() {
    const teacher = yield call(actions.getTeachers)
    yield put({type: 'SET_TEACHER', payload: teacher})
}

export function *createTeacher(action) {
    const teacher = yield call(actions.createTeacher, action.payload)
}
