import { gql } from '@apollo/client'
import client from './../apollo-settings'

const actions = {
    getService: async () => {
        try {
            const {data} = await client.query({
                query: gql`
                    query adminServiceResolver($params: ReactAdminListParams!) {
                        adminServiceList(params: $params) {
                            data {
                                id
                                title
                                isBlocked
                            }
                            total
                        }
                    }
                `,
                variables: {params: {pagination: {page: 1, perPage: 99999}, sort: {field: "id", order: "ASC"}, filter: []}}
            })
            return data ? data.adminServiceList.data : []
        } catch (e) {
            console.log(e)
        }
    }
}

export default actions
