import { gql } from '@apollo/client'
import client from './../apollo-settings'

const actions = {
    getTeachers: async () => {
        try {
            const {data} = await client.query({
                query: gql`
                    query adminTeacherResolver($params: ReactAdminListParams!) {
                        adminTeacherList(params: $params) {
                            data {
                                id
                                firstName
                                lastName
                                email
                                key
                            }
                            total
                        }
                    }
                `,
                variables: {params: {pagination: {page: 1, perPage: 99999}, sort: {field: "id", order: "ASC"}, filter: []}}
            })
            return data ? data.adminTeacherList.data : []
        } catch (e) {
            console.log(e)
        }
    },
    getTeacher: async (id) => {
        try {
            const {data} = await client.query({
                query: gql`
                    query adminTeacherGetOne($id: String!) {
                        adminTeacherGetOne(id: $id) {
                            id
                            firstName
                            lastName
                            email
                            key
                        }
                    }
                `,
                variables: {id: id.toString()}
            })
            // console.log(data)
            return data ? data.adminTeacherGetOne : []
        } catch (e) {
            console.log(e)
        }
    },
    createTeacher: async (teacher) => {
        try {
            const {data, errors} = await client.query({
                query: gql`
                mutation adminTeacherCreate($data: TeacherAdminInput!) {
                    adminTeacherCreate(data: $data) {
                        id
                    }
                }
                `,
                variables: {
                    data: teacher
                }
            })
            return {data, error: errors ? errors[0].message : null}
        } catch (e) {
            console.log(e)
        }
    },
    updateTeacher: async (id, data) => {
        try {
            const {admin} = await client.query({
                query: gql`
                    mutation adminTeacherUpdate($id: Int!, $data: TeacherAdminInput!) {
                        adminTeacherUpdate(id: $id, data: $data) {
                            id
                            lastName
                            firstName
                            email
                            key
                        }
                    }
                `,
                variables: {
                    id,
                    data
                }
            })
            return admin ? admin.adminTeacherUpdate : null
        } catch (e) {
            console.log(e)
            return null
        }
    },
    deleteTeacher: async (id) => {
        try {
            const {data} = await client.query({
                query: gql`
                    mutation adminTeacherDelete($id: Int!) {
                        adminTeacherDelete(id: $id) {
                            id
                            lastName
                            firstName
                            email
                            key
                        }
                    }
                `,
                variables: {
                    id
                }
            })
            return data ? data.adminTeacherDelete : null
        } catch (e) {
            console.log(e)
            return null
        }
    }
}

export default actions
