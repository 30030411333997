// **  Initial State
const initialState = []

const teacherReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_TEACHER':
            return action.payload
        default:
            return state
    }
}

export default teacherReducer
