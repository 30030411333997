import { takeEvery} from 'redux-saga/effects'
import {getTeacher, createTeacher} from "./teacher"
import {getAdministrators} from "./admin"
import {getService} from './service/index'
import {sagaLogin, sagaLogout, forgotPassword} from "./auth"


export default function* sagaWatcher() {
    yield takeEvery('LOGIN', sagaLogin)
    yield takeEvery('LOGOUT', sagaLogout)
    yield takeEvery('GET_SERVICE', getService)
    yield takeEvery('GET_TEACHER', getTeacher)
    yield takeEvery('GET_ADMINS', getAdministrators)
    yield takeEvery('CREATE_TEACHER', createTeacher)
    yield takeEvery('FORGOT_PASSWORD', forgotPassword)
}

